.filtersRow {
	display: inline-flex;
	flex-direction: row;
	width: 100%;
}

.btnDateRange {
	width: 100%;
	height: 38px;
	background: #ffffff !important;
	border-radius: 4px !important;
	border: 1px solid #d2d2d2 !important;
}

.btnDateRange span {
	font-family: Nunito;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 19px;
	letter-spacing: 0.4px;
	color: #333333;
	text-transform: none !important;
}

.btnDateRange span svg {
	font-size: 19px !important;
}

.iconDateRangeBtn {
	margin-left: auto;
}

.daterangepicker .ranges li.active,
.daterangepicker td.active,
.daterangepicker td.active:hover,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle,
.btn-primary {
	background-color: #10a2b7 !important;
}

.css-151xaom-placeholder {
	box-sizing: border-box;
	font-family: Nunito;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 19px;
	letter-spacing: 0.4px;
	color: #333333 !important;
}

.filtersRow .groupSelect {
	display: inherit;
	width: 45%;
	margin: 30px;
}

.filtersRow .dateRange {
	display: inherit;
	width: 30%;
	margin: 30px;
}

.filtersRow .groupSelect .label,
.filtersRow .dateRange .label {
	padding: 7px;
	font-family: Nuito;
	font-weight: 400;
	font-style: normal;
	font-size: 16px;
	line-height: 21.82px;
	width: max-content;
}

.filtersRow .groupSelect .selectList {
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: inherit;
	height: 30px;
	background: #ffffff;
	border-radius: 2px;
}

.filtersRow .groupSelect .selectList .css-bgvzuu-indicatorSeparator {
	display: none !important;
}

.filtersRow .groupSelect .selectList svg {
	fill: #333333 !important;
	width: 16px;
}

.filtersRow .groupSelect .selectList .css-1s2u09g-control {
	border-color: #ffffff;
	background-color: #ffffff;
}

.filtersRow .groupSelect span.question,
.filtersRow .dateRange span.question {
	width: 16px;
	height: 19px;
	font-family: Nunito;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 19px;
	padding: 7px;
	cursor: pointer;
}

.filtersRow .exportButton {
	margin: 30px;
}

.filtersRow .exportButton button {
	color: #ffffff;
	background: #10a2b7;
	float: right;
	width: 130px;
	height: 45px;
}

.filtersRow .exportButton button:disabled,
.filtersRow .exportButton button[disabled] {
	color: #000;
	background: #e2e0e0;
	float: right;
	width: 130px;
	height: 45px;
}

.filtersRow .exportButton button:disabled .iconBtn {
	color: #000;
}

.filtersRow .exportButton .buttonTxt {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	text-transform: none !important;
}

.filtersRow .exportButton .iconBtn {
	float: right;
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #ffffff;
}

.filtersRow .exportButton .exportOptions {
	width: 130px;
	margin-top: 45px;
	background: #fff;
	border: 1px solid rgba(0, 0, 0, 0.15);
	z-index: 1;
	position: absolute;
	border-radius: 4px;
	float: right;
}

.exportButton p.exportExcel span a {
	margin-top: 12px;
	margin-bottom: 0;
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #000000;
}

.exportButton p.exportCsv span a {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.4px;
	color: #000000;
}

.exportButton p.exportExcel:hover,
.exportButton p.exportCsv:hover {
	background-color: #eeeeee;
	cursor: pointer;
}

.exportButton p.exportExcel,
.exportButton p.exportCsv {
	margin: 5px 0 0 0;
	text-align: center;
}

.featuredContainer {
	display: inline-flex;
	flex-direction: row;
	width: 100%;
}

.featuredContainer .featuredInfo {
	height: 128px;
	width: 15%;
	border-radius: 20px;
	background: #ffffff;
	-webkit-box-shadow: 0px 7px 21px 0px #00000005;
	box-shadow: 0px 7px 21px 0px #00000005;
	margin: 30px;
	cursor: pointer;
	border: none !important;
}

.featuredContainer .featuredRequestInfo {
	height: 128px;
	width: 15%;
	border-radius: 20px;
	background: #ffffff;
	-webkit-box-shadow: 0px 7px 21px 0px #00000005;
	box-shadow: 0px 7px 21px 0px #00000005;
	margin: 30px;
	cursor: pointer;
	border: 1px solid #ab54db;
}

.featuredContainer .featuredImpressionInfo {
	height: 128px;
	width: 15%;
	border-radius: 20px;
	background: #ffffff;
	-webkit-box-shadow: 0px 7px 21px 0px #00000005;
	box-shadow: 0px 7px 21px 0px #00000005;
	margin: 30px;
	cursor: pointer;
	border: 1px solid #27ae60;
}

.featuredContainer .featuredClickInfo {
	height: 128px;
	width: 15%;
	border-radius: 20px;
	background: #ffffff;
	-webkit-box-shadow: 0px 7px 21px 0px #00000005;
	box-shadow: 0px 7px 21px 0px #00000005;
	margin: 30px;
	cursor: pointer;
	border: 1px solid #f2994a;
}

.featuredContainer .featuredCtrInfo {
	height: 128px;
	width: 15%;
	border-radius: 20px;
	background: #ffffff;
	-webkit-box-shadow: 0px 7px 21px 0px #00000005;
	box-shadow: 0px 7px 21px 0px #00000005;
	margin: 30px;
	cursor: pointer;
	border: 1px solid #2f80ed;
}

.featuredContainer .featuredFillRateInfo {
	height: 128px;
	width: 15%;
	border-radius: 20px;
	background: #ffffff;
	-webkit-box-shadow: 0px 7px 21px 0px #00000005;
	box-shadow: 0px 7px 21px 0px #00000005;
	margin: 30px;
	cursor: pointer;
	border: 1px solid #eb5757;
}

.featuredContainer .featuredInfo .featuredTitle,
.featuredContainer .featuredRequestInfo .featuredTitle,
.featuredContainer .featuredImpressionInfo .featuredTitle,
.featuredContainer .featuredClickInfo .featuredTitle,
.featuredContainer .featuredCtrInfo .featuredTitle,
.featuredContainer .featuredFillRateInfo .featuredTitle {
	width: 125.04px;
	height: 29.45px;
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 26px;
	letter-spacing: 0px;
	text-align: left;
	color: #1c2832;
	position: absolute;
	padding-left: 23.64px;
	padding-top: 13px;
}

.featuredContainer .featuredInfo .featuredMonyContainer,
.featuredContainer .featuredRequestInfo .featuredMonyContainer,
.featuredContainer .featuredImpressionInfo .featuredMonyContainer,
.featuredContainer .featuredClickInfo .featuredMonyContainer,
.featuredContainer .featuredCtrInfo .featuredMonyContainer,
.featuredContainer .featuredFillRateInfo .featuredMonyContainer {
	width: 179.84px;
	padding-left: 23.64px;
	padding-top: 37px;
	font-family: Nunito;
	font-style: normal;
	font-weight: 700;
	font-size: 24px !important;
	line-height: 33px;
	color: #1c2832;
	text-align: left;
}

.featuredContainer .featuredInfo .faturedRectangle hr,
.featuredContainer .featuredRequestInfo .faturedRectangle hr,
.featuredContainer .featuredImpressionInfo .faturedRectangle hr,
.featuredContainer .featuredClickInfo .faturedRectangle hr,
.featuredContainer .featuredCtrInfo .faturedRectangle hr,
.featuredContainer .featuredFillRateInfo .faturedRectangle hr {
	width: 8%;
	border-radius: 0px;
	position: absolute;
	margin-left: 23.64px;
	margin-top: 12px;
	border: 2px solid;
}

.featuredContainer .featuredInfo .faturedSeparator,
.featuredContainer .featuredRequestInfo .faturedSeparator,
.featuredContainer .featuredImpressionInfo .faturedSeparator,
.featuredContainer .featuredClickInfo .faturedSeparator,
.featuredContainer .featuredCtrInfo .faturedSeparator,
.featuredContainer .featuredFillRateInfo .faturedSeparator {
	padding-top: 10px;
}

.featuredContainer .featuredTotalContainer {
	padding-top: 22px;
	position: relative;
	width: 100%;
	height: 30px;
	font-family: Nunito;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 26px;
	color: #1c2832;
	text-align: left;
	margin-left: 22px;
}

.chartContainer {
	width: 100%;
	height: 450px;
}

.chartContainer .chart {
	width: 94%;
	height: 450px;
	background: #ffffff;
	border-radius: 10px;
	position: absolute;
	margin: 30px;
}

.chartContainer h3.chartTitle {
	height: 30px;
	font-family: Nunito;
	font-style: normal;
	font-weight: normal;
	font-size: 22px;
	line-height: 30px;
	color: #1c2832;
	margin: 30px;
	margin-bottom: 0;
}

.tableContainer {
	position: absolute;
	margin: 30px;
	width: 94%;
	margin-top: 105px;
}

.tableContainer input {
	margin-left: 25px !important;
	font-family: Nunito !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 16px !important;
	line-height: 22px !important;
	color: #1c2832 !important;
}

.tableContainer ::before,
.tableContainer ::after {
	border-bottom: none !important;
}

.tableContainer input::placeholder {
	color: #1c2832 !important;
	opacity: 1 !important;
}

.xAxis .recharts-text {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 26px;
	color: #333333;
}

.yAxis .recharts-text {
	font-family: Nuito;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 26px;
	color: #333333;
}

.recharts-tooltip-wrapper {
	color: #000000 !important;
	font-family: Nunito;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 19px;
	z-index: 999;
}

.recharts-tooltip-item-list li {
	/*color: #000000 !important;*/
	font-family: Nunito;
	font-style: bold;
	font-weight: 700;
	font-size: 13px;
	line-height: 18px;
}

*:focus {
	outline: 0 !important;
}

.noData {
	margin: auto;
	display: block;
	text-align: center;
	border: 1px solid #e2dddd;
	width: 500px;
	height: 300px;
	background: #fff;
}

.noData > span {
	font-family: Nunito;
	font-style: normal;
	font-weight: normal;
	font-size: 22px;
	color: #1c2832;
	vertical-align: middle;
	line-height: 300px;
}

.custom-tooltip {
	background: #ffffff;
	box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.06);
	color: #000000;
	width: 216px;
	height: max-content;
	border: 1px solid #e4e2e2;
	display: block;
	z-index: 999;
}

.custom-tooltip .label {
	font-family: Nunito;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 19px;
	color: #000000;
	margin-top: 10px;
	margin-left: 18px;
}

.custom-tooltip ul {
	margin-bottom: 0px !important;
	vertical-align: middle;
	list-style: disc;
}

.custom-tooltip ul li::marker {
	font-size: 2em;
}

.custom-tooltip .custom-tooltip-value {
	font-family: Nunito;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	color: #000000;
	margin: 0px;
}

.custom-tooltip ul li span {
	line-height: 30px;
}

/*---- media queries ----*/

@media only screen and (max-device-width: 1024px) {
	.filtersRow {
		display: inline-flex;
		flex-direction: column;
	}

	.filtersRow .dateRange div,
	.filtersRow .dateRange .react-bootstrap-daterangepicker-container {
		width: inherit;
	}

	.filtersRow .groupSelect,
	.filtersRow .dateRange {
		display: inherit;
		width: 100%;
		margin-top: 20px;
		padding-right: 7%;
	}

	.filtersRow .groupSelect .label,
	.filtersRow .dateRange .label {
		width: 115px;
	}

	.filtersRow .exportButton {
		width: 100%;
		margin-top: 20px;
	}

	.filtersRow .exportButton button {
		float: left;
	}

	.featuredContainer {
		display: inline-flex;
		flex-direction: column;
		padding: 30px;
		width: 100%;
	}

	.featuredContainer .featuredInfo,
	.featuredContainer .featuredRequestInfo,
	.featuredContainer .featuredImpressionInfo,
	.featuredContainer .featuredClickInfo,
	.featuredContainer .featuredCtrInfo,
	.featuredContainer .featuredFillRateInfo {
		width: 100%;
		margin-top: 20px;
		border: none;
		margin-left: 0;
	}

	.featuredContainer .featuredInfo .featuredTitle,
	.featuredContainer .featuredRequestInfo .featuredTitle,
	.featuredContainer .featuredImpressionInfo .featuredTitle,
	.featuredContainer .featuredClickInfo .featuredTitle,
	.featuredContainer .featuredCtrInfo .featuredTitle,
	.featuredContainer .featuredFillRateInfo .featuredTitle {
		width: 100%;
		height: 29.45px;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 26px;
		letter-spacing: 0px;
		text-align: center;
		color: #1c2832;
		padding-top: 13px;
		padding-left: 0px !important;
	}

	.featuredContainer .featuredInfo .featuredMonyContainer,
	.featuredContainer .featuredRequestInfo .featuredMonyContainer,
	.featuredContainer .featuredImpressionInfo .featuredMonyContainer,
	.featuredContainer .featuredClickInfo .featuredMonyContainer,
	.featuredContainer .featuredCtrInfo .featuredMonyContainer,
	.featuredContainer .featuredFillRateInfo .featuredMonyContainer {
		width: 100%;
		padding-top: 37px;
		font-family: Nunito;
		font-style: normal;
		font-weight: 700;
		font-size: 24px !important;
		line-height: 33px;
		color: #1c2832;
		text-align: center;
		margin-left: 2%;
	}

	.featuredContainer .featuredInfo .faturedRectangle,
	.featuredContainer .featuredRequestInfo .faturedRectangle,
	.featuredContainer .featuredImpressionInfo .faturedRectangle,
	.featuredContainer .featuredClickInfo .faturedRectangle,
	.featuredContainer .featuredCtrInfo .faturedRectangle,
	.featuredContainer .featuredFillRateInfo .faturedRectangle {
		display: -ms-inline-flexbox;
		display: inline-flex;
		width: 100%;
	}

	.featuredContainer .featuredInfo .faturedRectangle hr,
	.featuredContainer .featuredRequestInfo .faturedRectangle hr,
	.featuredContainer .featuredImpressionInfo .faturedRectangle hr,
	.featuredContainer .featuredClickInfo .faturedRectangle hr,
	.featuredContainer .featuredCtrInfo .faturedRectangle hr,
	.featuredContainer .featuredFillRateInfo .faturedRectangle hr {
		width: 75%;
		border-radius: 0px;
		position: absolute;
		margin-left: 23.64px;
		margin-top: 12px;
		border: 2px solid;
	}

	.featuredContainer .featuredInfo .faturedRectangle span.faturedIcon,
	.featuredContainer .featuredRequestInfo .faturedRectangle span.faturedIcon,
	.featuredContainer .featuredImpressionInfo .faturedRectangle span.faturedIcon,
	.featuredContainer .featuredClickInfo .faturedRectangle span.faturedIcon,
	.featuredContainer .featuredCtrInfo .faturedRectangle span.faturedIcon,
	.featuredContainer .featuredFillRateInfo .faturedRectangle span.faturedIcon {
		position: absolute;
		width: 89%;
		height: 13.59px;
	}

	.MuiSvgIcon-root {
		float: right;
	}

	.featuredContainer .featuredInfo .faturedSeparator hr {
		position: absolute;
		width: 100%;
		height: 1.13px;
		color: #eeeeee;
	}

	.featuredContainer .featuredTotalContainer {
		padding-top: 22px;
		padding-left: 8%;
		position: relative;
		width: 100%;
		height: 30px;
		font-family: Nunito;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		line-height: 26px;
		color: #1c2832;
		text-align: center;
	}

	.tableContainer {
		margin-left: 0;
		width: 100%;
	}
}

.grouping-tabs > .nav-item > a:hover {
	cursor:pointer;
}
